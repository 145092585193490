<template>
  <div>
    <header class="header webview">
      <!-- Top Header Area Start -->
      <section class="top-header">
        <div class="mainmenu-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg navbar-light">
                  <a class="navbar-brand" href="/">
                    <img
                      src="../../assets/images/RFwhite.png"
                      alt=""
                      style="max-width: 12%; float: left"
                    />
                  </a>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#main_menu"
                    aria-controls="main_menu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse fixed-height"
                    id="main_menu"
                  >
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item">
                        <a class="nav-link" href="/"
                          >Staking
                          <div class="mr-hover-effect"></div>
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="https://rage.fan/about-rage/"
                          target="_blank"
                          >About
                          <div class="mr-hover-effect"></div
                        ></a>
                      </li>

                      <!-- <li class="nav-item">
                        <a class="nav-link" href=""
                          >Contact
                          <div class="mr-hover-effect"></div
                        ></a>
                      </li> -->
                      <!-- <li class="nav-item">
                        <a class="nav-link" @click="opentrans()"
                          >Transactions
                          <div class="mr-hover-effect"></div
                        ></a>
                      </li> -->
                    </ul>
                    <!-- <a
                      href="#"
                   
                      data-toggle="modal"
                      data-target="#signin"
                      @click="openNav()"
                    >
                      Transactions</a
                    > -->
                    <a
                      v-if="address"
                      href=""
                      class="mybtn1"
                      data-toggle="modal"
                      data-target="#signin"
                      @click="connnect()"
                    >
                      <span>
                        {{
                          address.substring(0, 6) +
                          "..." +
                          address.substring(address.length - 4, address.length)
                        }}</span
                      ></a
                    >
                    <a
                      v-else
                      href=""
                      class="mybtn1"
                      data-toggle="modal"
                      data-target="#signin"
                      @click="connnect()"
                    >
                      Connect</a
                    >
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
    <header class="header mobileview">
      <section class="top-header">
        <div class="mainmenu-area">
          <img src="../assets/menu.png" alt="" @click="openNav()" />
          <img src="" alt="" />
        </div>
      </section>
    </header>
    <section class="breadcrumb-area play">
      <!-- <img
        class="bc-img"
        src="../../assets/images/breadcrumb/play.png"
        alt=""
      /> -->
      <div class="container">
        <div class="row" style="text-align: start">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-12">
                <h4 class="stakingtitle">&nbsp;{{ Name }}</h4>
              </div>
              <!-- <div class="col-lg-6" style="float:left">
                  
             </div> -->
            </div>
            <br />
            <!-- <span class="spanfont"> CONNECTED TO ETHEREUM NETWORK </span><br /><br /> -->
            <span class="spanfont"> YOUR ADDRESS </span>
            <br />
            <input
              v-model="this.address"
              style="width: 100%; border-radius: 10px; color: white"
              disabled
            />
            <br />
            <br />
            <div>
              <span class="spanfont"> Stake Amount </span>
              <br />
              <input
                v-model="amount"
                v-on:keypress="isNumber($event)"
                style="width: 100%; border-radius: 10px"
              />
              <br />
              <br />
              <div class="center">
                <button class="mybtn1" @click="allowanceErc20Raw">Stake</button
                >&nbsp;
                <button class="mybtn2" @click="confirmUnstake()">unstake</button
                >&nbsp;
                <button class="mybtn2" @click="showModal = true">
                  Withdrawal
                </button>
              </div>
            </div>
          </div>
          <div class="col" style="text-align: start">
            <div class="col">
              <section class="play-games" style="margin-top: 150px">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="gaming-activities">
                        <div class="gaming-activities-inner">
                          <div class="row">
                            <div class="single-activities boxstyle">
                              <div class="bottom-area">
                                <div
                                  class="row"
                                  style="
                                    color: white;
                                    font-size: small;
                                    text-align: start;
                                  "
                                >
                                  <!-- <div class="col-sm-6">Staked balance</div>
                                  <div class="col-sm-6">{{ balance }} RAGE</div> -->

                                  <div class="col-sm-6 margin-adjust botpad">
                                    Staked Balance
                                  </div>
                                  <div class="col-sm-6 margin-adjust botpad">
                                    <!-- {{ currentPlan.Maturity }} % -->
                                    {{ balance }}
                                  </div>
                                  <!-- <br />
                                  <br /> -->
                                  <div class="col-sm-6 margin-adjust botpad">
                                    Total Interest Earning
                                  </div>
                                  <div class="col-sm-6 margin-adjust botpad">
                                    {{ interestAmount }}
                                  </div>
                                  <!-- <br />
                                  <br /> -->
                                  <div class="col-sm-6 margin-adjust botpad">
                                    Minimum Staking Amount
                                  </div>
                                  <div class="col-sm-6 margin-adjust botpad">
                                    {{ currentPlan.Min.toLocaleString() }}
                                  </div>
                                  <!-- <br />
                                  <br /> -->
                                  <div class="col-sm-6 margin-adjust botpad">
                                    Maximum Staking Amount
                                  </div>
                                  <div class="col-sm-6 margin-adjust botpad">
                                    {{ currentPlan.Max.toLocaleString() }}
                                  </div>
                                  <!-- <br /><br /><br /> -->
                                </div>
                              </div>
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div class="tableAdjust" v-if="displayedPosts.length > 0">
        <h5>Recent Transactions</h5>
        <table class="table table-bordered" style="color: white">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Events</th>
            </tr>
          </thead>
          <tbody class="tbodayfont">
            <tr v-for="p in displayedPosts" :key="p">
              <td>{{ p.Date }}</td>
              <td>{{ p.Amount }}</td>
              <td>{{ p.status }}</td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination offset">
            <li class="page-item">
              <button
                type="button"
                class="page-link"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </button>
            </li>
            <li class="page-item">
              <button
                type="button"
                class="page-link"
                v-for="pageNumber in pages.slice(page - 1, page + 5)"
                @click="page = pageNumber"
                :key="pageNumber"
              >
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <button
                type="button"
                @click="page++"
                v-if="page < pages.length"
                class="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="overflow-auto">
        <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
      </div>
      <div id="transaction" class="sidepanel">
        <a href="javascript:void(0)" class="closebtn" @click="closetrans()"
          >×</a
        >
        <a href="#">Recent Staking Transactions</a>
        <a href="#">You do not have any recent Transactions</a>
      </div>
    </section>

    <div id="mySidepanel" class="sidepanel" style="color: white">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav()">×</a>
      <a href="#">Staking</a>
      <a href="" @click="opentrans()">Transactions</a>
      <a href="#">About</a>
      <a href="#">Contact</a>
    </div>
    <!-- <Scroll></Scroll> -->

    <!-- Breadcrumb Area End -->

    <!-- Play Games Area Start -->
    <section class="play-games">
      <div class="all-games">
        <div class="container">
          <div class="row" style="text-align: start">
            <div class="col-lg-6">
              <h4>About us</h4>
              <span class="footer-span">
                Rage.Fan is a fan-first decentralized fantasy sports platform
                where players will have the opportunity to acquire action cards
                based on NFTs to earn additional in-game points. Players will
                partake in an Augmented Reality based Token hunt platform to
                collect $RAGE coins. The platform will also host a one-of-kind
                Sports Oracle offering reliable secure off-chain sports data.
              </span>
            </div>
            <div class="col-lg-6">
              <h4>Contact us</h4>
              <span class="footer-span">
                Please contact us if you need any help. </span
              ><br /><br />
              <div>
                <a href="https://ragefan.medium.com/" target="blank">
                  <img
                    class="btm-img"
                    src="../assets/medium.png"
                    alt=""
                    style="border-radius: 19px" /></a
                >&nbsp;
                <a href="https://t.me/rage_fan" target="blank">
                  <img class="btm-img" src="../assets/twitter.png" alt="" /></a
                >&nbsp;
                <a href="https://twitter.com/RageFanSocial" target="blank">
                  <img class="btm-img" src="../assets/telegram.png" alt=""
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <transition name="modal" id="modal-template" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <b>Interest Withdrawal</b>
              </slot>
              <slot name="header">
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                  @click="showModal = false"
                ></i>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <input
                  placeholder="Enter Amount"
                  type="number"
                  style="width: 100%; border-radius: 10px"
                  v-model.number="interestWithdraw"
                />
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button class="mybtn2" @click="withdrawInterest()">
                  Withdrawal
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Modal For Approval -->
    <modal name="loading" @closed="loadingReset()">
      <br />
      {{ loadingMessage }}
      <br />
      <div class="loader"></div>
    </modal>

    <modal name="errorModal" @closed="loadingResetErrorModal()">
      <br />
      <i class="fas fa-exclamation-circle fa-5x"></i>
      <br />
      <br />
      {{ errorMessage }}
    </modal>

    <modal name="successModal" @closed="loadingResetSuccess()">
      <br />
      <i class="fas fa-check-circle fa-5x"></i>
      <br />
      <br />
      {{ successMessage }}
    </modal>
  </div>
</template>

<script>
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
var Web3 = require("web3");
import erc20ABI from "../../src/assets/erc20ABI.json";
// import stakeABI from "../../src/assets/stakeABI.json";
import flexiStakingABI from "../../src/assets/flexiStakingABI.json";
import Withdrawal from "./mdtable.vue";
import Swal from "sweetalert2";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    Withdrawal,
  },
  data() {
    return {
      sortDesc: "Date",
      sortDesc: true,
      userHistory: [],
      // perPage:5,
      // currentPage: 1,
      showModal: false,
      options: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { size: 3 },
        ],
      },
      isBusy: true,
      items: [
        {
          id: "0",
          Name: "Perpetual Staking",
          Cap_RAGE: 500000,
          Start_date: "23/09/2021",
          Start_time: "15:00",
          Days_open: null,
          Days_until: "75",
          Maturity: "30",
          Min: 1000,
          Max: 1000000,
          percentage: 0,
          percentageInWords: "0%",
          closed: false,
          timeStamp: 1631026800,
        },
        {
          id: "1",
          Name: "Flexi Staking",
          Cap_RAGE: 500000,
          Start_date: "23/09/2021",
          Start_time: "15:00",
          Days_open: null,
          Days_until: "No",
          Maturity: "25",
          Min: 2000,
          Max: 20000,
          percentage: 0,
          percentageInWords: "0%",
          closed: false,
          timeStamp: 1631026800,
        },
        {
          id: "2",
          Name: "Gold Staking",
          Cap_RAGE: 500000,
          Start_date: "02/09/2021",
          Start_time: "15:00",
          Days_open: "5",
          Days_until: "45",
          Maturity: "30",
          Min: 2000,
          Max: 50000,
          percentage: 0,
          percentageInWords: "0%",
          closed: true,
          timeStamp: 1628348400,
        },
        {
          id: "3",
          Name: "SPECIAL STAKING",
          Cap_RAGE: 500000,
          Start_date: "27/08/2021",
          Start_time: "15:00",
          Days_open: "",
          Days_until: "60",
          Maturity: "45",
          Min: 10000,
          Max: 50000,
          percentage: 0,
          percentageInWords: "0%",
          closed: true,
        },
      ],
      interestWithdraw: 0,
      currentPlan: {},
      address: "",
      showcon: false,
      uncon: true,
      Name: "",
      interestAmount: 0,
      useraddress: null,
      tokenAddress: "0xed9c6a04cf25c2930e475d37d77848b6025fbab2",
      stakeContractAddress: "0x393C9406Fb9A9628f1e07372AD5909CBE6123a9e",

      // New flexi conAddess
      // 0x393C9406Fb9A9628f1e07372AD5909CBE6123a9e
      // 0xBD13a0624f591ada252D9Be0BB86a6Cf64d4FDc0
      //Test Token and Contract
      // tokenAddress: "0xa0ccf79e7ba7bacf007dc010306f777e8c4e9f39",
      // stakeContractAddress: "0x4501ac4fb73ecba35a85374030b2aea1fd0a9b20",
      chainId: "4",
      chainName: "rinkeby",
      tokenInstance: null,
      stakeInstance: null,
      amount: 0,
      plan: null,
      balance: 0,
      loadingMessage: "In Progress",
      errorMessage: "Something Went Wrong",
      successMessage: "Staking is Sucessful",
      stakedLength: 0,
      stakedData: [],

      page: 1,
      perPage: 5,
      pages: [],
      posts: [""],
    };
  },
  methods: {
    openNav() {
      document.getElementById("mySidepanel").style.width = "50%";
    },

    closeNav() {
      document.getElementById("mySidepanel").style.width = "0";
    },
    opentrans() {
      document.getElementById("transaction").style.width = "50%";
    },

    closetrans() {
      document.getElementById("transaction").style.width = "0";
    },
    showLoadingModal(message) {
      this.$modal.show("loading");
      this.loadingMessage = message ? message : "In Progress";
    },

    isWithdrawDisabled(maturityDate) {
      try {
        var maturityDateObject = new Date(maturityDate);
        var currentDate = new Date();
        if (currentDate > maturityDateObject) {
          return false;
        } else {
          return true;
        }
      } catch (error) {
        return true;
      }
    },

    hideLoadingModal() {
      this.$modal.hide("loading");
      this.loadingMessage = "In Progress";
    },

    loadingReset() {
      this.loadingMessage = "In Progress";
    },

    showErrorModal(message) {
      this.hideLoadingModal();
      this.hideSuccessModal();
      this.$modal.show("errorModal");
      this.errorMessage = message ? message : "Something Went Wrong";
    },

    hideErrorModal() {
      this.$modal.hide("errorModal");
      this.errorMessage = "Something Went Wrong";
    },

    loadingResetErrorModal() {
      this.errorMessage = "Something Went Wrong";
    },

    showSuccessModal(message) {
      this.$modal.show("successModal");
      this.errorMessage = message ? message : "Something Went Wrong";
    },

    hideSuccessModal() {
      this.$modal.hide("successModal");
      this.errorMessage = "Something Went Wrong";
    },

    loadingResetSuccess() {
      this.errorMessage = "Something Went Wrong";
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async createTokenInstance() {
      if (typeof window.ethereum !== "undefined") {
        this.tokenInstance = new window.web3.eth.Contract(
          erc20ABI,
          this.tokenAddress
        );
        return Promise.resolve();
      } else {
        this.showErrorModal("Token Contract Initializition Failed");
        return Promise.reject();
      }
    },

    async createStakeInstance() {
      if (typeof window.ethereum !== "undefined") {
        this.stakeInstance = new window.web3.eth.Contract(
          flexiStakingABI,
          this.stakeContractAddress
        );
        return Promise.resolve();
      } else {
        this.showErrorModal("Staking Contract Initializition Failed");
        return Promise.reject();
      }
    },
    convertWeiToEther(wei) {
      return window.web3.utils.fromWei(wei.toString(), "ether");
    },
    async getStakedBalance() {
      let self = this;
      if (window.ethereum) {
        if (window.ethereum.networkVersion.toString() != self.chainId) {
          self.showErrorModal(
            `Connect to ${self.chainName} Chain and Continue`
          );
          return;
        }
      }

      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      try {
        let balance = await this.stakeInstance.methods
          .balanceOf(self.address)
          .call();
        self.balance = this.convertWeiToEther(balance);
      } catch (error) {
        console.error("error in getting balance", error);
        self.balance = "-";
      }
    },

    async getStakedInterest() {
      let self = this;
      if (window.ethereum) {
        if (window.ethereum.networkVersion.toString() != self.chainId) {
          self.showErrorModal(
            `Connect to ${self.chainName} Chain and Continue`
          );
          return;
        }
      }

      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      try {
        let balance = await this.stakeInstance.methods
          .earned(self.address)
          .call();
        self.interestAmount = this.convertWeiToEther(balance);
      } catch (error) {
        console.error("error in getting balance", error);
        self.interestAmount = "-";
      }
    },
    async getStakedData(length = 0) {
      let self = this;
      self.stakedData = [];
      if (window.ethereum) {
        if (window.ethereum.networkVersion.toString() != self.chainId) {
          self.showErrorModal(
            `Connect to ${self.chainName} Chain and Continue`
          );
          return;
        }
      }

      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      try {
        for (let i = 0; i < length; i++) {
          let stakedData = await this.stakeInstance.methods
            .stakeInfo(self.plan, self.address, i)
            .call();
          var tokenInt = parseInt(
            self.convertWeiToEther(stakedData._stakedToken)
          );
          var interestToken = self.convertWeiToEther(stakedData._interestToken);
          var totalTokens = self.convertWeiToEther(stakedData._maturityToken);

          var maturityDate = new Date(
            parseInt(stakedData._maturityDate) * 1000
          ).toLocaleString();
          let formatedData = {
            startDate: new Date(
              parseInt(stakedData._createdDate) * 1000
            ).toLocaleString(),
            maturityDate: maturityDate,
            stakedTokens: tokenInt.toLocaleString(),
            rewardTokens: interestToken.toLocaleString(),
            totalTokens: totalTokens.toLocaleString(),
            withdraw: self.isWithdrawDisabled(maturityDate),
          };
          self.stakedData.push(formatedData);
        }
        self.isBusy = false;
      } catch (error) {
        console.error("error in getting balance", error);
        self.stakedData = [];
        console.error(error);
      }
    },

    async getStakedLength() {
      let self = this;
      self.isBusy = true;
      if (window.ethereum) {
        if (window.ethereum.networkVersion.toString() != self.chainId) {
          self.showErrorModal(
            `Connect to ${self.chainName} Chain and Continue`
          );
          return;
        }
      }

      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      try {
        let length = await this.stakeInstance.methods
          .fixedStakeLengthByUser(self.plan, self.address)
          .call();
        self.stakedLength = length;
        if (length > 0) {
          self.getStakedData(length);
        } else {
          self.stakedData = [];
        }
      } catch (error) {
        console.error("error in getting balance", error);
        self.stakedLength = 0;
        self.stakedData = [];
        console.log(error);
      }
    },

    async stakeToken() {
      let self = this;
      var openDate = new Date(this.currentPlan.timeStamp);
      var currentDate = new Date();
      if (currentDate < openDate) {
        self.showErrorModal(
          `Staking will start at ${this.currentPlan.Start_date} ${currentPlan.Start_time} UTC`
        );
      }
      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      self.loadingMessage = "Staking In Progress";
      var approvedAmountInWei = window.web3.utils.toWei(self.amount.toString());
      let gas;
      try {
        gas = await self.stakeInstance.methods
          .stake(approvedAmountInWei)
          .estimateGas({
            from: self.address,
          });
      } catch (error) {
        self.showErrorModal("Staking failed");
        return;
      }
      const price = await window.web3.eth.getGasPrice();

      self.stakeInstance.methods
        .stake(approvedAmountInWei)
        .send({
          from: self.address,
          gas: gas + Math.floor(gas * 0.1),
          gasPrice: Number(price) + Math.floor(Number(price) * 0.1),
        })
        .on("transactionHash", function (hash) {
          console.log("Transcation Hash Approved For Stake", hash);
        })
        .once("confirmation", function (confirmationNumber, receipt) {
          console.log("Transcation Receipt For Stake", receipt);
          self.hideLoadingModal();
          //   self.showSuccessModal("Staking is Successful");

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Staking is Successful",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            window.location.reload();
          });
          //  self.getWithdrawalhistory();

          self.metamaskConnected();
        })
        .on("error", function (eror) {
          self.showErrorModal("Staking Transaction Failed");
          console.error(eror);
        });
    },

    async withdrawInterest() {
      this.showModal = false;
      let self = this;
      var approvedAmountInWei = window.web3.utils.toWei(
        self.interestWithdraw.toString()
      );
      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      self.showLoadingModal("Withdraw In Progress");
      let gas;
      try {
        gas = await self.stakeInstance.methods
          .withdrawInterest(approvedAmountInWei)
          .estimateGas({
            from: self.address,
          });
      } catch (error) {
        self.showErrorModal("Insufficent fund in Contract");
        return;
      }
      const price = await window.web3.eth.getGasPrice();

      self.stakeInstance.methods
        .withdrawInterest(approvedAmountInWei)
        .send({
          from: self.address,
          gas: gas + Math.floor(gas * 0.1),
          gasPrice: Number(price) + Math.floor(Number(price) * 0.1),
        })
        .on("transactionHash", function (hash) {
          console.log("Transcation Hash Approved For withdraw", hash);

          //   self.showSuccessModal("Transaction Sumbited Successfully");

          // Swal.fire({
          //   position: "center",
          //   icon: "success",
          //   title: "Withdrawn successfully",
          //   showConfirmButton: false,
          //   timer: 3000,
          // });
        })
        .once("confirmation", function (confirmationNumber, receipt) {
          console.log("Transcation Receipt For withdraw", receipt);
          self.hideLoadingModal();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Withdrawn successfully",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            // self.getWithdrawalhistory();
            // this.getstakehistory();
            // this.getUnstakehistory();
            window.location.reload();
          });
          self.metamaskConnected();
        })
        .on("error", function (eror) {
          self.showErrorModal("Withdraw Transaction Failed");
          console.error(eror);
        });
    },

    confirmUnstake() {
      var unstakeAmount = parseFloat(
        this.balance + this.interestAmount
      ).toFixed(5);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title:
            "Your rewards will stop if you proceed with this transaction <br> Are you sure?",
          text: "You will receive " + unstakeAmount + " $Rage in your wallet",
          // icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Unstake",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            // swalWithBootstrapButtons.fire(
            //   "Deleted!",
            //   "Your file has been deleted.",
            //   "success"
            // );
            this.unStakeToken();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your staked amount is safe :)",
              "error"
            );
          }
        });
    },
    async unStakeToken() {
      let self = this;
      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      self.showLoadingModal("Withdraw In Progress");
      let gas;
      try {
        gas = await self.stakeInstance.methods.exit().estimateGas({
          from: self.address,
        });
      } catch (error) {
        self.showErrorModal("Insufficent fund in Contract");
        return;
      }
      const price = await window.web3.eth.getGasPrice();

      self.stakeInstance.methods
        .exit()
        .send({
          from: self.address,
          gas: gas + Math.floor(gas * 0.1),
          gasPrice: Number(price) + Math.floor(Number(price) * 0.1),
        })
        .on("transactionHash", function (hash) {
          console.log("Transcation Hash Approved For withdraw", hash);
        })
        .once("confirmation", function (confirmationNumber, receipt) {
          console.log("Transcation Receipt For withdraw", receipt);
          self.hideLoadingModal();
          //   self.showSuccessModal("Transaction Sumbited Successfully");

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Unstaked successfully",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            window.location.reload();
          });
          self.metamaskConnected();
        })
        .on("error", function (eror) {
          self.showErrorModal("Withdraw Transaction Failed");
          console.error(eror);
        });
    },

    async approveToken() {
      let self = this;
      console.log();
      var openDate = new Date(this.currentPlan.timeStamp);
      var currentDate = new Date();
      if (currentDate < openDate) {
        self.showErrorModal(
          `Staking will start at ${this.currentPlan.Start_date} ${this.currentPlan.Start_time} UTC`
        );
        return;
      }
      if (!this.tokenInstance) {
        try {
          await self.createTokenInstance();
        } catch (error) {
          self.showErrorModal("Token Contract Initializition Failed");
          return;
        }
      }

      var approveAmountInWei = window.web3.utils.toWei(self.amount.toString());
      let gas;
      try {
        gas = await self.tokenInstance.methods
          .approve(self.stakeContractAddress, approveAmountInWei)
          .estimateGas({
            from: self.address,
          });
      } catch (error) {
        console.error(error);
        self.showErrorModal("Gas Estimation Failed");
        return;
      }
      const price = await window.web3.eth.getGasPrice();

      self.tokenInstance.methods
        .approve(self.stakeContractAddress, approveAmountInWei)
        .send({
          from: self.address,
          gas: gas + Math.floor(gas * 0.1),
          gasPrice: Number(price) + Math.floor(Number(price) * 0.1),
        })
        .on("transactionHash", function (hash) {
          console.log("Transcation Hash", hash);
        })
        .once("confirmation", function (confirmationNumber, receipt) {
          self.loadingMessage = "Approval Confirmed";
          self.stakeToken();
          console.log("Transcation Receipt", receipt);
        })
        .on("error", function (error) {
          self.showErrorModal("Approve Transaction Failed");
        });
    },
    async allowanceErc20Raw() {
      if (!this.disableStake) {
        let self = this;
        if (window.ethereum) {
          if (window.ethereum.networkVersion.toString() != self.chainId) {
            self.showErrorModal(
              `Connect to ${self.chainName} Chain and Continue`
            );
            return;
          }
        }

        if (!this.tokenInstance) {
          try {
            await self.createTokenInstance();
          } catch (error) {
            return self.showErrorModal("Token Contract Initializition Failed");
          }
        }
        try {
          self.showLoadingModal("Allowance Checking");
          let allowance = await this.tokenInstance.methods
            .allowance(self.address, self.stakeContractAddress)
            .call();
          let allowanceInEther = allowance / Math.pow(10, 18);
          if (allowanceInEther >= parseInt(self.amount)) {
            self.loadingMessage = "Stake in Progress";
            self.stakeToken();
          } else {
            self.loadingMessage = "Approve in Progress";
            self.approveToken();
          }
        } catch (error) {
          self.hideLoadingModal();
          self.showErrorModal("Error in getting allowance");
          console.error("Error in getting allowance", error);
        }
      } else {
        if (this.amount < parseInt(this.currentPlan.Min)) {
          this.showErrorModal(
            `Stake Amount should be greater than ${this.currentPlan.Min} $Rage`
          );
        } else {
          this.showErrorModal(
            `Stake Amount should be less than ${this.currentPlan.Max} $Rage`
          );
        }
      }
    },
    metamaskConnected() {
      let self = this;
      self.getStakedBalance();
      self.getStakedInterest();
      window.ethereum.on("accountsChanged", function (accounts) {
        if (self.address != accounts[0]) {
          self.address = accounts[0];
          self.getStakedBalance();
          self.getStakedInterest();
          self.getWithdrawalhistory();
          // self.getstakehistory();
          // self.getUnstakehistory();
        }
      });
    },

    connnect() {
      if (typeof window.ethereum !== "undefined") {
        let self = this;
        ethereum.request({ method: "eth_requestAccounts" }).then(function () {
          window.web3 = new Web3(ethereum);
          window.web3.eth.getAccounts(function (err, accounts) {
            if (err != null) console.error("An error occurred: " + err);
            else if (accounts.length == 0)
              console.log("User is not logged in to MetaMask");
            else {
              console.log("User is logged in to MetaMask", accounts);
              self.address = accounts[0];
              //  litionNetwork()
              self.metamaskConnected();
              window.location.reload();
            }
          });
          return;
        });
      }
    },
    litionNetwork() {
      if (typeof window.ethereum !== "undefined") {
        let self = this;
        window.web3 = new Web3(window.ethereum);
        // console.log("is connect", window.ethereum.isConnected());
        window.web3.eth.getAccounts(function (err, accounts) {
          if (err != null) console.error("An error occurred: " + err);
          else if (accounts.length == 0) {
            // self.getOptions();
          } else {
            self.address = accounts[0];
            self.metamaskConnected();
            // self.getOptions();
            self.getWithdrawalhistory();
            // self.getstakehistory();
            // self.getUnstakehistory();
          }
        });
      }
    },

    async getWithdrawalhistory() {
      let self = this;
      let Whistory = [];
      let stakehistory = [];
      let unstakehistory = [];
      this.userHistory = [];
      if (window.ethereum) {
        if (window.ethereum.networkVersion.toString() != self.chainId) {
          self.showErrorModal(
            `Connect to ${self.chainName} Chain and Continue`
          );
          return;
        }
      }

      if (!this.stakeInstance) {
        try {
          await self.createStakeInstance();
        } catch (error) {
          self.showErrorModal("Staking Contract Initializition Failed");
          return null;
        }
      }
      try {
        Whistory = await this.stakeInstance.methods
          .getUserInterestWithdrawData(self.address)
          .call();

        for (let i = 0; i < Whistory[0].length; i++) {
          this.userHistory.push({
            Date: new Date(parseInt(Whistory[1][i]) * 1000).toLocaleString(),
            Amount: this.convertWeiToEther(Whistory[0][i]),
            status: "Interest withdrawal",
          });
        }

        stakehistory = await this.stakeInstance.methods
          .getUserStakeTokenData(self.address)
          .call();

        for (let i = 0; i < stakehistory[0].length; i++) {
          this.userHistory.push({
            Date: new Date(
              parseInt(stakehistory[1][i]) * 1000
            ).toLocaleString(),
            Amount: this.convertWeiToEther(stakehistory[0][i]),
            status: "Stake",
          });
        }

        unstakehistory = await this.stakeInstance.methods
          .getUserStakeWithdrawData(self.address)
          .call();

        for (let i = 0; i < unstakehistory[0].length; i++) {
          this.userHistory.push({
            Date: new Date(
              parseInt(unstakehistory[1][i]) * 1000
            ).toLocaleString(),
            Amount: this.convertWeiToEther(unstakehistory[0][i]),
            status: "Unstake",
          });
        }
        // console.log("userHistory", this.userHistory);
        const sorted = this.userHistory.sort((a, b) => {
          const aDate = new Date(a.Date);
          const bDate = new Date(b.Date);

          return bDate.getTime() - aDate.getTime();
        });

        console.log(sorted);
        this.getPosts();
        // self.balance = this.convertWeiToEther(balance);
      } catch (error) {
        console.error("error in getting balance", error);
        self.balance = "-";
      }
    },

    getPosts() {
      let data = [];
      this.posts = this.userHistory;
      // for(let i = 0; i < 50; i++){
      //   this.posts.push({first: 'John',
      //          last:'Doe',
      //          suffix:'#' + i});
      // }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.posts.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return posts.slice(from, to);
    },
  },
  computed: {
    //  rows() {
    //   console.log("entered in to row")
    //   return this.items.length;
    // },
    disableStake() {
      if (this.amount) {
        if (
          this.amount >= parseInt(this.currentPlan.Min) &&
          this.amount <= parseInt(this.currentPlan.Max)
        ) {
          return false;
        }
      }
      return true;
    },
    displayedPosts() {
      return this.paginate(this.posts);
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  mounted() {
    this.plan = this.$route.params.id;
    this.currentPlan = this.items[this.$route.params.id];
    this.address = this.$route.params.address;
    this.Name = this.items[this.$route.params.id].Name;
  },
  created() {
    this.litionNetwork();

    window.scrollTo(0, 0);
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 14px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.botpad {
  padding-bottom: 10px;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: #595959;
  font-size: 1.475em !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-cancel {
  margin: 10px !important;
}

.btn-success {
  color: #fff;
  background-color: #252f5a !important;
  border-color: #252f5a !important;
}

.btn-danger {
  color: #fff;
  background-color: #198754 !important;
  border-color: #198754 !important;
}
/* .table {
  --bs-table-bg: #252f5a !important;
} */
@media only screen and (max-width: 799px) {
  .webview {
    display: none;
  }
}
@media only screen and (min-width: 800px) {
  .mobileview {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .stakingtitle {
    font-size: 38px;
    line-height: 1.2380952380952381;
  }
}
.spanfont {
  color: white;
  font-size: smaller;
}
.boxstyle {
  border-radius: 25px;
  padding: 40px !important;
}
.footer-span {
  color: white;
  font-size: 14px;
}
.margin-adjust {
  margin-top: 20px;
}
.btm-img {
  width: 40px;
}
.vhl-btn-right {
  margin-left: auto;
  margin-right: 0px !important;
}
.vhl-btn-left {
  margin-left: 0px !important;
  margin-right: auto;
}
.vhl-item {
  padding-left: 15px !important;
}
.sidepanel {
  width: 0;
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 15%;
  right: 0;
  background-color: #252f5a;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidepanel a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidepanel a:hover {
  color: #f1f1f1;
}

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}
</style>
<style scoped>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.breadcrumb-area.play {
  padding: 80px 0px 100px;
}
.play-games .gaming-activities .gaming-activities-inner .single-activities {
  background: #252f5a;
  padding: 0px 19px 17px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.mybtn2 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0px 12px;
  display: inline-block;
  color: #fff;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  background-image: linear-gradient(to left, #ff344d, #d2273c);
}
.tableAdjust {
  padding-right: 50px;
  padding-left: 50px;
}
.tbodayfont {
  font-size: 14px;
}
@media only screen and (max-width: 700px) {
  /* .offset {
    width: 0px !important;
    margin: 20px;
} */

  .tableAdjust {
    padding-right: 10px;
    padding-left: 10px;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
  .center {
    text-align: center !important;
  }
  .mybtn1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 14px 35px;
    display: inline-block;
    color: #fff;
    border: 0px;
    width: 193px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    background-image: linear-gradient(to left, #ff344d, #d2273c);
    text-align: center;
  }
  .mybtn2 {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0px 12px;
    display: inline-block;
    color: #fff;
    border: 0px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    background-image: linear-gradient(to left, #ff344d, #d2273c);
    margin-top: 10px !important;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import staking from '../views/staking.vue'
import flexiStaking from '../views/flexiStaking.vue'
import mdtable from '../views/mdtable.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/staking/:id',
    name: 'staking',
    component: staking
  },
  {
    path: '/flexiStaking/:id',
    name: 'flexiStaking',
    component: flexiStaking
  },
  {
    path: '/mdtable',
    name: 'mdtable',
    component: mdtable
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

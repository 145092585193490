<template>
  <div class="overflow-auto">
    <b-table
      style="color: white"
      id="my-table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="userHistory"
      :per-page="perPage"
      :current-page="currentPage"
      small
    ></b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      style="text-align: center"
    ></b-pagination>

    <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import flexiStakingABI from "../../src/assets/flexiStakingABI.json";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
var Web3 = require("web3");
import erc20ABI from "../../src/assets/erc20ABI.json";
export default {
  data() {
    return {
      userHistory: [],
      perPage: 15,
      currentPage: 1,
      items: [
        { Date: "19-11-2021", WithdrawalAmount: "2000" },
        { Date: "19-11-2021", WithdrawalAmount: "1000" },
        { Date: "19-11-2021", WithdrawalAmount: "100" },
        { Date: "19-11-2021", WithdrawalAmount: "10" },
        { Date: "19-11-2021", WithdrawalAmount: "200" },
        { Date: "19-11-2021", WithdrawalAmount: "300" },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {},
  mounted() {
    this.$root.$on("userHistory", (value) => {
      console.log("mainchart", value);
      this.userHistory = value;
    });
  },
  created() {},
};
</script>